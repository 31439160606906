@media screen and (max-width: 768px) {

  /* For mobile phones: */
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {

  /* For mobile phones: */
  .desktop-view {
    display: flex;
  }

  .mobile-view.mobile-column {
    display: none;
  }
}

.mobile-row {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.mobile-column {
  display: flex;
  flex-direction: column;
}

.line {
  justify-content: space-between;
  flex-wrap: wrap;
}

b {
  padding-right: 5px;
}

.contents .mobile-row:not(:first-child) {
  font-size: 12px;
  color: gray;
}

.contents {
  width: 100%;
  padding: 10px;
}

/* .collapsed .single-table-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.single-table-item {
  display: flex;
  flex-grow: 1;
}

.desktop-view .action-wrapper {
  display: flex;
}
