.nutrition-table {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.nutrition-table-header {
    font-weight: bold;
    font-size: 2em;
    border-bottom: 3px solid black;
}

.nutrition-table-row {
    border-bottom: 1px solid black;
    display: flex;
}

.nutrient-name {
    font-weight: bold;
    padding-right: 5px;
}