:root {
  --nav-bar-icon-size: 1.3em;
  --accent-color: #345053;
  --secondary-accent-color: lightslategray;
  --actions-width: 25%;
  --quantity-width: 15%;
}

:root {
  font-size: 16px;
  background-color: #eceff1;
}

@media screen and (max-width: 768px) {
  :root {
    --actions-width: 60px;
  }

  /* For mobile: */
  .navigation-item-text {
    display: none;
  }

  .results-wrapper {
    flex-direction: column;
    flex-grow: 1;
  }

  .mobile-background {
    height: 100%;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    overflow-y: scroll;
  }

  .mobile-column>div:nth-of-type(2n) {
    background-color: #f3f3f3;
  }

  .mobile-background .category-list {
    position: static;
    background-color: white;
  }

  .mobile-background.hidding {
    display: none;
  }

  i.mobile-button {
    display: flex;
  }

  .lists-wrapper {
    flex-direction: column;
  }

  .filters .category-list {
    position: absolute;
    left: 0;
    background-color: white;
    z-index: 20;
    height: fit-content;
  }

  div .lists-wrapper>div {
    width: 100%;
  }
}

.app-content {
  padding-top: 5px;
  flex-grow: 1;
}

.app-footer {
  padding: 10px;
}

.navigation-item-text {
  font-size: 18px;
}

.mobile-button {
  display: none;
}

.dialog-content {
  padding: 10px;
}

.clickable-text {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  padding: 5px;
}

.row {
  display: flex;
  align-items: center;
}

.alert {
  color: #555;
  border-radius: 10px;
  font-family: Tahoma, Geneva, Arial, sans-serif;
  font-size: 11px;
  padding: 10px 10px 10px 36px;
  margin: 10px;
  font-weight: .8em;
}

.warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  margin: unset;
  border-radius: unset;
}

.error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  cursor: pointer;
}

.video-overlay {
  height: 100px;
  width: 200px;
  border: 5px solid red;
  position: absolute;
  top: 50%;
}

.contents .row:first-child {
  flex-direction: column;
  align-items: flex-start;
}

.search.row {
  border-width: 2px;
  border-bottom: 1px solid lightgray;
  border-radius: 5px;
  align-items: center;
  width: 100%;
  background-color: white;
}

.filters {
  width: 222px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 0px 10px;
}

.filters .category-header {
  margin: 0px;
  padding: 5px 0px;
}

.filter-button {
  display: none;
}

.filters .category-items {
  padding-left: 0px;
}

.filters .category-item {
  padding: 2px;
  width: fit-content;
}

.filters .category-item>div>div {
  display: flex;
  align-items: flex-end;
}

.expiration-row {
  display: flex;
  align-items: center;
}

.expiration-row i,
.expiration-row img {
  visibility: hidden;
}

.expiration-row:hover i,
.expiration-row:hover img {
  visibility: visible;
}

.search input::-webkit-calendar-picker-indicator {
  display: none !important;
}

table:not(.desktop-view) .action-wrapper {
  align-self: flex-start;
  padding-top: 20px;
}

.actions-column {
  display: flex;
  align-items: center;
  width: var(--actions-width);
}

.cart th.info-column,
.cart td.info-column {
  width: calc((100% - var(--actions-width) - var(--quantity-width))/2);
}

.cart th.quantity-column,
.cart td.quantity-column {
  width: var(--quantity-width);
}

.inventory th.info-column,
.inventory td.info-column {
  width: calc((100% - var(--actions-width))/3);
}

.health-risks .info-column {
  display: flex;
  align-items: center;
}

.page-list-cap {
  display: flex;
  align-items: flex-end;
}

.page-list-header {
  font-weight: 600;
  padding-right: 10px;
}

.actions-column .icon.fa {
  font-size: 18px;
}

.toxic-label {
  border: 2px solid red;
  color: black;
  border-radius: 50px;
  width: 1.2em;
  height: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
  margin-right: 10px;
  margin-bottom: 5px;
}

.results-wrapper {
  display: flex;
}

.search .textinput-wrapper {
  flex-grow: 1;
}

.inventory .textinput-wrapper input,
.cart .textinput-wrapper input {
  width: 100%;
}

.search.row input,
.search.row input:focus {
  border: none;
  outline: none;
  line-height: 26px;
  width: 100%;
}

.navigation {
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  border-bottom: 1px solid #dddddd;
}

.navigation .navigation-bar-item {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .5em;
  cursor: pointer;
}

.navigation-bar-item.nav-selected {
  background-color: var(--accent-color);
  color: white;
}

.navigation .nav-selected .icon {
  -webkit-filter: invert(100%);
  /* Safari/Chrome */
  filter: invert(100%);
}

.icon {
  width: var(--nav-bar-icon-size);
  height: var(--nav-bar-icon-size);
  padding-right: 8px;
}

.icon.disabled {
  pointer-events: none;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.nav-icon-wrap {
  display: inline-block;
  position: relative;
  text-align: center;
  padding-top: 6px;
}

.nav-numbers {
  font-size: 10px;
  background-color: red;
  width: 10px;
  line-height: 12px;
  text-align: center;
  color: #fff;
  z-index: 2;
  border-radius: 3px;
  position: absolute;
  left: 16px;
}

.health-risks {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.health-risk-text {
  white-space: normal;
  width: 100%;
}

.single-result {
  border: 1px solid;
  margin: 10px;
  padding: 10px;
  padding-top: 0;
}

.toxic-big-banner {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

div:not(.single-result) .no-toxic-symbol {
  font-size: 32px;
}

.no-toxic-symbol {
  color: green;
  font-size: 45px;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chat-wrapper {
  flex-grow: 1;
  line-height: 1.25;
  font-family: "SanFrancisco";
  font-size: 1.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-hist-wrapper {
  overflow: auto;
  flex-grow: 1;
}

.write-message {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
}

.write-message textarea {
  padding: 5px;
  margin: 5px;
  font-size: 1.25rem;
  width: 100%;
  height: 4rem;
  border-radius: 0.25rem;
}

.send-button {
  padding: 5px;
  padding-right: 10px;
}

.message {
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
  max-width: 600px;
  padding: 0.5rem 1.5rem;
  border-radius: 1.15rem;
  max-width: 75%;
  padding: 0.5rem .875rem;
  position: relative;
  word-wrap: break-word;
}

.message.from-user {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
  margin-left: 5rem;
  margin-right: 1rem;
}

.message.from-bot {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
  margin-right: 5rem;
  margin-left: 1rem;
}

.expired {
  color: #f44336;
}

.soon-to-expire {
  color: #ff9800;
}

.lists-wrapper {
  display: flex;
}

.lists-wrapper>div {
  padding: 5px;
  width: 50%;
}

.inventory,
.cart,
.results {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  table-layout: fixed;
}

.results {
  font-size: .75em;
  margin: 5px;
}

th,
td {
  padding: 12px 15px;
}

.inventory td,
.cart td {
  text-align: center;
}

tbody tr {
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type,
.inventory .header,
.cart .header {
  border-bottom: 2px solid var(--accent-color);
}

.new-cart-item {
  padding: 5px;
  display: flex;
  align-items: center;
}

.category-items .category-item:nth-of-type(odd) {
  background-color: #f3f3f3;
}

.category-items .category-item {
  border-bottom: 1px solid #dddddd;
  padding: 12px 15px;
  width: 100%;
}

.pages {
  display: flex;
  justify-content: center;
}

.citations {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.citations div {
  display: flex;
  justify-content: center;
}

.table-label {
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 5px;
  font-size: large;
  background-color: var(--accent-color);
  color: white;
  margin-bottom: 5px;
}

.table-label img {
  filter: invert(100%);
}

.preference-dlg {
  background-color: white;
}

.preference-dlg .dialog-content {
  padding: 5px 10px 10px 10px;
}

.preference-dlg .section:not(:first-child) {
  margin-top: 10px;
  border-top: 1px solid lightgray;
  padding-top: 2px;
}

.preference-dlg .option {
  cursor: pointer;
}

.filters .category-item .preferences {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dlg-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.hide .FoodRecall iframe {
  height: 0 !important;
}

.show,
.show .FoodRecall {
  height: 100%;
}

.show .FoodRecall iframe {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
}

/* DO NOT WORK
.FoodRecall .footer-link-container,
.FoodRecall .footer-controls {
  top: -15px;
  position: relative;
}

.FoodRecall .footer-controls .footer-links {
  display: none !important;
}

.footer-link-container a.footer-link {
  margin: 0 !important;
} */

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}