.dialog {
    padding: 0;
    border-radius: 5px;
    pointer-events: all;
    background: white;
    position: absolute;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--light-blue);
    padding: 5px 5px 0px 10px;
    align-items: center;    
    border-bottom: 1px solid lightgray;
}

.dialog-header div {
    padding-bottom: 5px;    
    padding-right: 10px;    
    font-weight: 500;
}

.dialog-content {
    padding: 0px 5px 5px 5px;
}

.btn.close-btn {
    border-radius: 50px;
    border: none;    
    width: 28px;
    padding: 0px;
}

button,
.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 4px 8px;
    margin: 3px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }
  
  button:hover,
  .btn:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  
  button.primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  
  button.primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
  }